export default class Mobilemenu {
  init(root) {
      this.root = root
      this.toggle = document.querySelector('.mobile-menu-toggle')
      this.mobileMenu = document.querySelector('.menu-mobile')
      this.links = this.root.querySelectorAll('.menu-item')
      this.render()
  }

  render() {
    this.toggle.addEventListener('click', e => {
      this.toggle.classList.toggle('is-active')
      this.mobileMenu.classList.toggle('is-active')
    })
    this.links.forEach(item => {
      item.addEventListener('click', e => {
        this.toggle.classList.remove('is-active')
        this.mobileMenu.classList.remove('is-active')
      })
    })
  }
}

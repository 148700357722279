export default class Features {
  init(root) {
    this.root = root;

    this.imagePlaceholder = this.root.querySelector('#ipad image');
    this.featureItems = this.root.querySelectorAll('.feature');
    this.captionPlaceholder = this.root.querySelector('.features-caption');

    console.log(this.imagePlaceholder);
    this.addEvents();
    this.setActive(this.featureItems[0]);
  }

  addEvents() {
    this.featureItems.forEach((item) => {
      const featureImage = item.querySelector('.feature-image');
      const imageUrl = featureImage.getAttribute('data-image');
      const caption = featureImage.getAttribute('data-caption');

      item.addEventListener('click', () => {
        this.setActive(item);
        this.imagePlaceholder.setAttribute('xlink:href', imageUrl);
        this.captionPlaceholder.textContent = caption;
      });
    });
  }

  setActive(activeItem) {
    this.featureItems.forEach((item) => {
      if (item === activeItem) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });

    const featureImage = activeItem.querySelector('.feature-image');
    const imageUrl = featureImage.getAttribute('data-image');
    const caption = featureImage.getAttribute('data-caption');

    this.imagePlaceholder.setAttribute('xlink:href', imageUrl);
    this.captionPlaceholder.textContent = caption;
  }
}

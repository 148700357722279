import Plyr from 'plyr';

export default class FounderVideo {
  init(root) {
    this.root = root;
    this.wrapper = this.root.querySelector('.founder-video-wrapper');
    this.playButton = this.root.querySelector('.founder-video-button');
    this.video = this.root.querySelector('.video');

    this.player = new Plyr(this.video, {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
    });

    this.pauseTimeout = null; // Timeout ID for delay
    this.isMousePressed = false; // Flag to track mouse press
    this.isTouchStarted = false; // Flag to track touch start

    this.render();
  }

  render() {
    this.playButton.addEventListener('click', () => {
      this.wrapper.classList.add('cover-hidden');
      this.player.play();
    });

    document.addEventListener('mousedown', () => {
      this.isMousePressed = true;
    });

    document.addEventListener('mouseup', () => {
      this.isMousePressed = false;
    });

    document.addEventListener('touchstart', () => {
      this.isTouchStarted = true;
    });

    document.addEventListener('touchend', () => {
      this.isTouchStarted = false;
    });

    // The playback bar triggers pause events as well. That's why we check for mouse up or touch end and add a delay.
    this.player.on('pause', () => {
      // Clear any previous timeout
      clearTimeout(this.pauseTimeout);

      // Start new timeout to check if the player is still paused after a delay
      this.pauseTimeout = setTimeout(() => {
        if (this.player.paused && !this.isMousePressed && !this.isTouchStarted) {
          this.wrapper.classList.remove('cover-hidden');
        }
      }, 300);
    });

    this.player.on('play', () => {
      // Clear the timeout if the player starts playing before the delay
      clearTimeout(this.pauseTimeout);
    });
  }
}

import Plyr from 'plyr';


export default class Video {
  init(root) {
      this.root = root
      const player = new Plyr(this.root, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
      });
    }
}

import '../scss/app.scss';

// Your JS Code goes here
import Video from '../components/video/video.js'
import FounderVideo from '../components/foundervideo/foundervideo.js'
import Features from '../components/features/features.js'
import Mobilemenu from '../components/mobilemenu/mobilemenu.js'
import ScrollFadeIn from '../components/scrollfadein/scrollfadein.js'
import Storytelling from '../components/storytelling/storytelling.js'

//math & events
import {lenisScrollTo} from './utils/events.js';


let videos = document.querySelectorAll(".simple-video");
videos.forEach(videoEl => {
  let video = new Video();
  video.init(videoEl)
})

let founderVideos = document.querySelectorAll(".founder-video");
founderVideos.forEach(founderVideoEl => {
  let founderVideo = new FounderVideo();
  founderVideo.init(founderVideoEl)
})

let features = document.querySelectorAll(".features");
features.forEach(featuresEl => {
  let features = new Features();
  features.init(featuresEl)
})

let storytelling = document.querySelectorAll(".storytelling");
let storytellingInstances = []
storytelling.forEach(storytellingEl => {
  let storytelling = new Storytelling();
  storytellingInstances.push(storytelling)
  storytelling.init(storytellingEl)
})
// console.log(storytellingInstances)

//populate desktop and mobile menu with jumplinks
let menu = document.querySelector(".header .menu");
let mobileMenu = document.querySelector(".menu-mobile-jumplinks");
let jumplinks = document.querySelectorAll(".jumplink");

jumplinks.forEach(jumplinkEl => {
  var id = jumplinkEl.id;
  var dataTitle = jumplinkEl.getAttribute('data-title');
  var anchorLink = document.createElement('a');
  anchorLink.classList.add('menu-item', 'button-text');
  anchorLink.href = '#' + id;
  anchorLink.textContent = dataTitle;
  menu.appendChild(anchorLink);

  var clonedLink = anchorLink.cloneNode(true);
  mobileMenu.appendChild(clonedLink);

  anchorLink.addEventListener('click', scrollToElement);
  clonedLink.addEventListener('click', scrollToElement);

  function scrollToElement(event) {
    event.preventDefault(); // Prevent default link behavior
    //stop scrollSnap in Scrollytelling
    storytellingInstances.forEach(instance => {
      instance.snapInProgress = true;
      setTimeout(() => {
        instance.snapInProgress = false;
      }, 1)
    })
    var element = document.querySelector('#' + id);
    lenisScrollTo(element, 0.5);
  }
});

//skip button
const skipButton = document.querySelector('.storytelling .skip-button')
if(skipButton) {
  skipButton.addEventListener('click', (event) => {
    event.preventDefault(); // Prevent default link behavior
    var element = document.querySelector(skipButton.getAttribute('href'));
    lenisScrollTo(element, 0.5);
  })
}


//needs to be run after adding jumplinks
let mobilemenuEl = document.querySelector(".menu-mobile");
if(mobilemenuEl) {
  let mobilemenu = new Mobilemenu();
  mobilemenu.init(mobilemenuEl)
}


//fade in highlights
const fadeinEls = document.querySelectorAll('u, .fade-on-scroll, ol>li, ul, h1, h2, h3, p, figure, .button .primary, .storytelling-counter')
fadeinEls.forEach(el => {
  let fadein = new ScrollFadeIn();
  fadein.init(el)
})


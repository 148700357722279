export const map = (n, start1, stop1, start2 = 0, stop2 = 1) => {
	return ((n-start1)/(stop1-start1))*(stop2-start2)+start2;
};

export const clamp = (number, min = 0, max = 1) => {
	return Math.max(min, Math.min(number, max));
}

export const lerp = (value1, value2, amount) => {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
}

export const loopback = (i, m = 100) => {
	//returns 1 while value is progressing, returns 0 if value is 0 or 1
	//based on triangle wave function
	i = clamp(i) //normalize value to 0-1
	return Math.ceil(Math.abs((i*(m)) % (m)) / m)
}


export const spanFerkel = (s) => {
	if ( s ) {
		return `<mark>${
			s.split( '' ).map(
				c => ( c.toLowerCase() !== c ? `<span>${c}</span>` : `<span>${c}</span>` ),
			).join( '' )
				.split( ' ' )
				.join( '</mark> <mark>' )
		}</span>`;
	}
	return '';
};

//Penner's easing functions: https://github.com/danro/jquery-easing/blob/master/jquery.easing.js

/* ============================================================
 * jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
 *
 * Open source under the BSD License.
 *
 * Copyright © 2008 George McGinley Smith
 * All rights reserved.
 * https://raw.github.com/danro/jquery-easing/master/LICENSE
 * ======================================================== */


export const easeOutElastic = (time, duration, valueStart, valueChange) => {
  var s=1.70158;var p=0;var a=valueChange;
  if (time==0) return valueStart;  if ((time/=duration)==1) return valueStart+valueChange;  if (!p) p=duration*.3;
  if (a < Math.abs(valueChange)) { a=valueChange; var s=p/4; }
  else var s = p/(2*Math.PI) * Math.asin (valueChange/a);
  return a*Math.pow(2,-10*time) * Math.sin( (time*duration-s)*(2*Math.PI)/p ) + valueChange + valueStart;
}

import Lenis from '@studio-freight/lenis'
import { loopback, map, clamp } from './math.js';
import { gsap } from "gsap";

// import Preloader from '../../components/preloader/preloader';

export let doc = {
  // w: window.innerWidth,
  // h: window.innerHeight
  w: document.querySelector('.resize-helper').offsetWidth,
  h: document.querySelector('.resize-helper').offsetHeight
};

const getWindowSize = () => {
  doc = {
    // w: window.innerWidth,
    // h: window.innerHeight
    w: document.querySelector('.resize-helper').offsetWidth,
    h: document.querySelector('.resize-helper').offsetHeight
  }
  // r.style.setProperty('--100vh', `${doc.h}px`);
}

export const siteurl = document.body.dataset.siteurl

let r = document.querySelector(':root');
r.style.setProperty('--100vh', `${ doc.h }px`);

export let cursor = {
  x: 0.5,
  y: 0.5,
};

export let scrl = {
  scroll: 0,
  velocity: 0,
  direction: 'vertical',
  progress: 0
}

export let touchDevice = isTouchDevice()

function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}


export let registeredLoops = []; //this is set to be empty on page

export const inViewport = (elPos) => {
  const progress = map(scrl.scroll + doc.h, elPos.y, elPos.y + elPos.h + doc.h);
  return {
    progress: progress,
    loopback: loopback(progress)
  }
}

export const getPosition = (element) => {
  return {
    x: element.getBoundingClientRect().left,
    y: element.getBoundingClientRect().top + document.documentElement.scrollTop,
    w: element.offsetWidth,
    h: element.offsetHeight,
  }
}

export const updatePositions = () => {
  // console.log(registeredLoops)
  registeredLoops.forEach(el => {
    if (el.position) {
      el.position = getPosition(el.root);
    }
  })
}
updatePositions()


let smoothScroll = true;
// if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
//   if(doc.w > 960) { //desktop only
//     smoothScroll = false;
//   }
// }

//init Lenis scroll
const lenisOptions = {
  duration: 1,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  direction: 'vertical', // vertical, horizontal
  gestureDirection: 'vertical', // vertical, horizontal, both
  smooth: smoothScroll,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
}

export let lenis;


const addLenis = () => {
  document.querySelector('.container').classList.remove('onload')
  lenis = new Lenis(lenisOptions)

  //save scroll value
  lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
    //console.log({ scroll, limit, velocity, direction, progress })
    scrl = {
      scroll: scroll,
      velocity: velocity,
      direction: direction,
      progress: progress
    }
  })
  lenis.start()
}

addLenis()

// global requestAnimationFrame, orginally for Lenis Scroll
function raf(time) {
  lenis.raf(time)

  registeredLoops.forEach(el => { //calls all functions that have been added to array
    el.loop(time)
  })
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)


export const stopLenis = () => {
  lenis.stop()
}
export const startLenis = () => {
  lenis.start()
}
export const lenisScrollTo = (el, duration, offset = 0) => {
  // console.log(el)
  // lenis.scrollTo(el, {lock: true, force: true, duration: duration, offset: offset, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2})  //https://easings.net/en#easeInOutCubic
  lenis.scrollTo(el, { lock: true, force: true, duration: duration, offset: offset })
}


// let preloader = new Preloader();
// preloader.init(document.querySelector('.preloader'));

window.onload = function () {
  //positions()
  updatePositions()
  // preloader.enterSite();
};

//slow loading of media, and media changing size on interaction can cause a miscalculation of positions
//this is a very general patch to update the positions without observing specific elements
window.setTimeout(() => {
  updatePositions()
}, 1000);

let updateInterval = 2000;
let intervalId;

const changeUpdateInterval = () => {
  clearInterval(intervalId);
  updateInterval = 10000;
  intervalId = setInterval(updatePositions, updateInterval);
};

intervalId = setInterval(updatePositions, updateInterval);
setTimeout(changeUpdateInterval, 15000);


// export let headerHeight;
// const getHeaderHeight = () => {
//   //headerHeight = document.querySelector('header').offsetHeight
// }
// getHeaderHeight()


//let gyroscopeActive = false;

document.addEventListener('mousemove', (event) => {
  //if(gyroscopeActive == false) {
  cursor = {
    x: map(event.clientX, 0, doc.w),
    y: map(event.clientY, 0, doc.h),
  }
  //console.log(cursor)
  //}
})


export const addGlobalEventListener = (type, selector, callback) => {
  document.addEventListener(type, e => {
    if (e.target.matches(selector)) callback(e)
  })
}

let lastTouchX = 0.5;
export let touchState = 0.5;

if (touchDevice == true) {
  document.addEventListener('touchstart', e => {
    lastTouchX = map(e.changedTouches[0].clientX, 0, doc.w)
  })

  document.addEventListener('touchmove', e => {
    touchState = clamp(touchState + (lastTouchX - map(e.changedTouches[0].clientX, 0, doc.w)))
    lastTouchX = map(e.changedTouches[0].clientX, 0, doc.w)
  })
}


//resize observer watching a helper (preventing ios url bar resize)
export let registeredResizeFunctions = []; //can be pushed to in individual component
let resizeCount = 0;
let doit;
const resizeObserver = new ResizeObserver((entries) => {
  // for (const entry of entries) {}
  if (resizeCount > 0) { //ignore observer being called on load
    getWindowSize()
    clearTimeout(doit); //cancle the timeout if new resize happend in the meantime
    doit = setTimeout(resizeUpdated, 500);
  }
  resizeCount++;
});
const resizeUpdated = () => {
  // console.log('resize')
  // getHeaderHeight()
  updatePositions()
  // console.log(registeredResizeFunctions)
  registeredResizeFunctions.forEach(el => {
    el.resize()
  })
}

resizeObserver.observe(document.querySelector(".resize-helper"));

/*
document.querySelector('body').addEventListener('click', () => {
	// console.log('click')
	if (typeof DeviceOrientationEvent.requestPermission === 'function') {
		DeviceOrientationEvent.requestPermission()
		.then(permissionState => {
			if (permissionState === 'granted') {
				// console.log('device orientation granted')
				window.addEventListener('deviceorientation', ( e ) => {
					gyroscopeActive = true;
					let orientationX;
					let orientationY;
					// console.log(e.gamma, e.beta);
					orientationX = clamp(e.gamma, -45, 45);
					orientationY = clamp(e.beta, -45, 45);

					//assign to cursor variable
					cursor = {
						x: map(orientationX, -45, 45),
						y: map(orientationY, -45, 45),
					}


				});
			}
		})
		.catch(console.error);
	}
})
*/

import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, lenisScrollTo, stopLenis, startLenis, touchDevice} from './../../js/utils/events.js';
import Lottie from 'lottie-web';

export default class Storytelling {
  init(root) {
    this.root = root
    this.sections = this.root.querySelectorAll('.storytelling-section');

    this.snapInProgress = false;
    this.snapDuration = 0.5;
    this.activeSection = -1;
    this.snapEnabled = true;
    this.scrollPausedDuration = 0;

    this.handleNavigation()

    registeredLoops.push({ //add this.loop to global requestAnimationFrame
      root: this.root,
      component: this,
      position: getPosition(this.root),
      // position: getPosition(document.querySelector('.project-list')),
      loop: this.loop
    })

    this.allLottieAnimations = [];
    this.lottieSetup()
  }

  lottieSetup() {
    this.lottieContainers = document.querySelectorAll('.lottie-container');
    this.allLottieAnimations = [];

    // Preload the JSON file
    const jsonPath = this.lottieContainers[0].getAttribute('data-src');
    let preloadedAnimationData;

    fetch(jsonPath)
      .then(response => response.json())
      .then(data => {
        preloadedAnimationData = data;

        // Load and configure individual animations using the preloaded JSON data
        this.lottieContainers.forEach((container, i) => {
          const singleAnimation = Lottie.loadAnimation({
            container: container,
            animationData: preloadedAnimationData,
            loop: false,
            autoplay: false
          });
          this.allLottieAnimations.push(singleAnimation);
          container.style.opacity = 0;
        });

        // console.log(this.allLottieAnimations);

        // Function to handle endFrame of desktop animation
        this.lottieContainers[0].style.opacity = 1;
        const onEnterFrame = (event) => {
          if (event.currentTime > this.endFrame) {
            this.allLottieAnimations[0].goToAndStop(this.endFrame, true);
          }
        };

        this.allLottieAnimations[0].addEventListener('enterFrame', onEnterFrame);
      })
      .catch(error => {
        console.error('Error loading animation JSON:', error);
      });
  }


  playSection(animation, startFrame, endFrame) {
    const currentFrame = this.allLottieAnimations[0].currentFrame;

    this.endFrame = endFrame

    this.allLottieAnimations[0].pause()

    // Check if the difference between currentFrame and startFrame is less than 100
    let startDifference = startFrame - currentFrame;

    if (startDifference < 50 && startDifference >= 0) {
      this.allLottieAnimations[0].goToAndPlay(currentFrame, true);
    } else if (startDifference < 0) {
      // console.log('fade');
      this.lottieContainers[0].style.opacity = 0;
      setTimeout(() => {
        this.lottieContainers[0].style.opacity = 1;
        this.allLottieAnimations[0].goToAndPlay(startFrame, true);
      }, 300);
    } else {
      this.allLottieAnimations[0].goToAndPlay(startFrame, true);
    }
  }

  playMobileLottie(section, i) {
    this.activeSection = i;
    // console.log('play section: ' + i)
    this.lottieContainers[i].style.opacity = 1;

    const startFrame = parseInt(section.getAttribute('data-start'));
    const pauseFrame = parseInt(section.getAttribute('data-pause'));
    this.allLottieAnimations[i].playSegments([[startFrame, pauseFrame]], true)
    // this.allLottieAnimations[i].addEventListener('complete', () => {
      // this.allLottieAnimations[i].goToandStop(pauseFrame, true)
      // this.allLottieAnimations[i].stop()
    // })
  }


  sectionPlayback(section) {
    const startFrame = parseInt(section.getAttribute('data-start'));
    const pauseFrame = parseInt(section.getAttribute('data-pause'));
    // const endFrame = parseInt(section.getAttribute('data-end'));

    this.playSection(this.animation, startFrame, pauseFrame);
  }


  handleNavigation() {
    let navigationAreas = [
      document.querySelector('.menu'),
      document.querySelector('.book-a-demo-button'),
      document.querySelector('.skip-button'),
      document.querySelector('.menu-mobile')
    ]

    navigationAreas.forEach(el => { //disable snap for a moment to scroll to anchorlink
      el.addEventListener('click', () => {
        this.snapEnabled = false;
        // console.log(this.snapEnabled)
        setTimeout(() => {
          this.snapEnabled = true;
          // console.log(this.snapEnabled)
        }, 1000)
      })
    })
  }

  goSnap(section, i) {
    // console.log('snap to section: ' + i)
    this.activeSection = i;
    this.snapInProgress = true;
    this.sectionPlayback(section)
    stopLenis()
    lenisScrollTo(section, this.snapDuration);
    setTimeout(() => {
      startLenis()
      this.snapInProgress = false;
    },this.snapDuration * 2000)
  }

  loop(time) {
    const storytellingInView = inViewport(this.position)

    if(storytellingInView.loopback === 1 && this.component.snapInProgress === false /*&& doc.w > 720*/) {
      // console.log(storytellingInView)
      // console.log(this.component.snapInProgress)

      if(this.component.snapEnabled === true) {
        this.component.sections.forEach((section, i) => {
          const position = getPosition(section)
          const inView = inViewport(position)
          // console.log(inView)
          if(scrl.direction === 1 && inView.progress > 0.1 && inView.progress < 0.49 && this.component.activeSection - i != 0 && doc.w >= 720) {
            // console.log(this.component.activeSection)
            this.component.goSnap(section, i);
          }
          if(scrl.direction === -1 && inView.progress < 0.9 && inView.progress > 0.51 && this.component.activeSection - i != 0 && doc.w >= 720) {
            // console.log(this.component.activeSection)
            this.component.goSnap(section, i);
          }

          if(doc.w < 720 && inView.progress > 0 && inView.progress < 0.4 && this.component.allLottieAnimations[i].isPaused && this.component.activeSection - i != 0 ) {
            // console.log(this.component.activeSection)
            this.component.playMobileLottie(section, i)
          }
        })
      }

    }

  }

}


import { doc, cursor, scrl, getPosition, inViewport, registeredLoops, lenisScrollTo, stopLenis, startLenis, touchDevice} from './../../js/utils/events.js';
import Lottie from 'lottie-web';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation.min.css';

export default class Storytelling {
  init(root) {
    this.root = root;
    this.storytellingInView = false;
    this.lottieReady = false;
    this.next = root.querySelector('.swiper-button-next');
    this.prev = root.querySelector('.swiper-button-prev');
    this.swiper = new Swiper(this.root, {
        modules: [Navigation],
        spaceBetween: 20,
        navigation: {
            nextEl: this.next,
            prevEl: this.prev,
        }
    });

    this.lottieSetup()

    this.swiper.on('slideChange', (e) => { // Arrow function preserves "this"
      updatePagination(this.swiper);
      const currentSlide = this.swiper.slides[this.swiper.realIndex];    
      this.sectionPlayback(currentSlide); // Now it correctly refers to the class method
    });
    

    function updatePagination(sw) {
      const currentPage = sw.realIndex + 1; // realIndex starts from 0
      const totalPages = sw.slides.length;
      sw.el.querySelector('.swiper-pagination').textContent = `${currentPage} / ${totalPages}`;
    }
    updatePagination(this.swiper) //set inital pagination


    registeredLoops.push({ //add this.loop to global requestAnimationFrame
      root: this.root,
      component: this,
      position: getPosition(this.root),
      loop: this.loop
    })
  }



  lottieSetup() {
    this.lottieContainers = document.querySelectorAll('.lottie-container');
    this.allLottieAnimations = [];

    // Preload the JSON file
    const jsonPath = this.lottieContainers[0].getAttribute('data-src');
    let preloadedAnimationData;

    fetch(jsonPath)
      .then(response => response.json())
      .then(data => {
        preloadedAnimationData = data;
        this.lottieReady = true;

        // Load and configure individual animations using the preloaded JSON data
        this.lottieContainers.forEach((container, i) => {
          const singleAnimation = Lottie.loadAnimation({
            container: container,
            animationData: preloadedAnimationData,
            loop: false,
            autoplay: false
          });
          this.allLottieAnimations.push(singleAnimation);
          container.style.opacity = 0;
        });

        // Function to handle endFrame of desktop animation
        this.lottieContainers[0].style.opacity = 1;
        const onEnterFrame = (event) => {
          if (event.currentTime > this.endFrame) {
            this.allLottieAnimations[0].goToAndStop(this.endFrame, true);
          }
        };

        this.allLottieAnimations[0].addEventListener('enterFrame', onEnterFrame);
      })
      .catch(error => {
        console.error('Error loading animation JSON:', error);
      });
  }


  playSection(animation, startFrame, endFrame) {
    const currentFrame = this.allLottieAnimations[0].currentFrame;

    this.endFrame = endFrame

    this.allLottieAnimations[0].pause()

    // Check if the difference between currentFrame and startFrame is less than 100
    let startDifference = startFrame - currentFrame;

    if (startDifference < 50 && startDifference >= 0) {
      this.allLottieAnimations[0].goToAndPlay(currentFrame, true);
    } else if (startDifference < 0) {
      this.lottieContainers[0].style.opacity = 0;
      setTimeout(() => {
        this.lottieContainers[0].style.opacity = 1;
        this.allLottieAnimations[0].goToAndPlay(startFrame, true);
      }, 300);
    } else {
      this.allLottieAnimations[0].goToAndPlay(startFrame, true);
    }
  }


  sectionPlayback(section) {
    const startFrame = parseInt(section.getAttribute('data-start'));
    const pauseFrame = parseInt(section.getAttribute('data-pause'));
    // const endFrame = parseInt(section.getAttribute('data-end'));

    this.playSection(this.animation, startFrame, pauseFrame);
  }

  loop(time) {
    const inView = inViewport(this.position)

    //play lottie animation of currently visible slide once in view and lottie is ready
    if(this.component.storytellingInView === false && inView.loopback === 1) { 
      const currentSlide = this.component.swiper.slides[this.component.swiper.realIndex];
      
      if(this.component.lottieReady) {
        this.component.storytellingInView = true;
        this.component.sectionPlayback(currentSlide); // Now it correctly refers to the class method
      }
    } else if (inView.loopback != 1) {
      this.component.storytellingInView = false;
    }
  }

}

